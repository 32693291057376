import BaseClient from "./BaseClient";
import { SetPreferredAccountInput } from "./models";

export interface IPreferencesClient {
	setPreferredAccount(input: SetPreferredAccountInput): Promise<void>;
}

export default class PreferencesClient
	extends BaseClient
	implements IPreferencesClient
{
	public async setPreferredAccount(
		input: SetPreferredAccountInput
	): Promise<void> {
		await this.sendPatch("/preferences/account", input);
	}
}
