import React, { Dispatch, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { IApiClient } from "../api/ApiClient";
import { UnauthorizedError } from "../api/Error";
import { ApiContext } from "../context";

export interface ApiResponse<T> {
	data: T | null;
	error: string | null;
	loading: boolean;
	setLoading: Dispatch<React.SetStateAction<boolean>>;
	setError: Dispatch<React.SetStateAction<string | null>>;
}

export interface ApiCallback<T> {
	(api: IApiClient): Promise<T>;
}

const useApiCallback = <T>(callback: ApiCallback<T>): ApiResponse<T> => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<string | null>(null);
	const api = useContext(ApiContext);
	const navigate = useNavigate();

	useEffect(() => {
		setLoading(true);
		callback(api)
			.then(data => {
				setData(data);
				setLoading(false);
				setError(null);
			})
			.catch(e => {
				const error = e as UnauthorizedError;
				if (error) {
					navigate("/");
				}
				setError(e.message);
			});
	}, [api, navigate, callback]);

	return {
		loading,
		data,
		error,
		setLoading,
		setError,
	};
};

export default useApiCallback;
