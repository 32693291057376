import { createContext } from "react";
import ApiClient, { IApiClient } from "./api/ApiClient";
import { IAuthManager, AuthConfigBuilder, AuthManager } from "./auth";

const baseURL = "http://localhost:8080";

var authConfig = new AuthConfigBuilder({})
	.setLoginUrl(baseURL + "/oauth/monzo")
	.build();
var authManager = new AuthManager(authConfig);
export const AuthContext = createContext<IAuthManager>(authManager);

var apiClient = new ApiClient(authManager, baseURL);
export const ApiContext = createContext<IApiClient>(apiClient);

export { authManager, apiClient };
