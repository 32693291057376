import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router";
import auth from "../../auth.jpg";
import { useApi } from "../../hooks";

const Continue: FunctionComponent = () => {
	const navigate = useNavigate();
	const api = useApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);

	const handleContinue = () => {
		if (loading) {
			return;
		}

		setLoading(true);
		api.webhooks
			.registerHooks()
			.then(() => {
				setLoading(false);
				setError(null);
				navigate("/account/select");
			})
			.catch((e: Error) => {
				setError(e);
				setLoading(false);
			});
	};

	return (
		<div className="max-w-md mx-auto text-white">
			<div className="top-0 pt-14 py-6 md:mx-6 px-2">
				<div className="text-2xl mb-6">
					Before you continue, make sure to grant access in your{" "}
					<span className="text-blue-500">Monzo</span> app.
				</div>
				<img
					src={auth}
					alt="An image of the access pop up in the Monzo app"
					className="w-100"
				/>
			</div>
			<div className="py-6 md:mx-6 px-2">
				{error && <p className="pb-3 text-red-600">{error.message}</p>}
				<div className="flex mx-auto lg:max-w-5xl items-center justify-end">
					<div className="flex">
						<div className="ml-auto">
							<span className="ml-2">
								<button
									className="align-middle items-center p-2 bg-dark-primary rounded-md shadow-sm hover:bg-dark-secondary ease-linear transition-all duration-150"
									onClick={handleContinue}
								>
									{loading ? "Loading..." : "Continue"}
								</button>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Continue;
