import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../hooks";

const Index: FunctionComponent = () => {
	const auth = useAuth();
	const navigate = useNavigate();

	const handleLogin = async () => {
		if (await auth.isAuthenticated()) {
			navigate("/app");
		} else {
			auth.login();
		}
	};

	return (
		<div className="flex max-w-2xl mx-auto py-8 h-screen text-white">
			<div className="m-auto grid grid-flow-row grid-cols-2">
				<div className="text-4xl">
					An automation tool for your{" "}
					<span className="text-blue-500">Monzo</span> pots.
				</div>
				<div className="flex justify-center">
					<button
						className="p-4 px-16 bg-dark-primary rounded-md text-2xl font-bold shadow-lg hover:bg-dark-secondary ease-linear transition-all duration-150 disabled:hover:bg-dark-primary"
						onClick={handleLogin}
					>
						Start
					</button>
				</div>
			</div>
		</div>
	);
};

export default Index;
