import { IAuthManager } from "../auth";
import AccountsClient, { IAccountsClient } from "./Accounts";
import BaseClient from "./BaseClient";
import { WhoAmI } from "./models";
import PotAllocationClient, { IPotAllocationClient } from "./PotAllocation";
import PotsClient, { IPotsClient } from "./Pots";
import PreferencesClient, { IPreferencesClient } from "./Preferences";
import WebhooksClient, { IWebhooksClient } from "./Webhooks";

export interface IApiClient {
	readonly potAllocations: IPotAllocationClient;
	readonly accounts: IAccountsClient;
	readonly preferences: IPreferencesClient;
	readonly pots: IPotsClient;
	readonly webhooks: IWebhooksClient;

	whoAmI(): Promise<WhoAmI>;
}

export default class ApiClient extends BaseClient implements IApiClient {
	public readonly potAllocations: IPotAllocationClient;
	public readonly accounts: IAccountsClient;
	public readonly preferences: IPreferencesClient;
	public readonly pots: IPotsClient;
	public readonly webhooks: IWebhooksClient;

	constructor(auth: IAuthManager, baseUrl: string) {
		super(auth, baseUrl);

		this.potAllocations = new PotAllocationClient(auth, baseUrl);
		this.accounts = new AccountsClient(auth, baseUrl);
		this.preferences = new PreferencesClient(auth, baseUrl);
		this.pots = new PotsClient(auth, baseUrl);
		this.webhooks = new WebhooksClient(auth, baseUrl);
	}

	public async whoAmI(): Promise<WhoAmI> {
		return await this.sendGet<WhoAmI>("/whoami");
	}
}
