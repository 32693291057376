export default class ApiError extends Error {
	constructor(
		public readonly error: string,
		public readonly message: string
	) {
		super(message);
	}
}

export class UnauthorizedError extends ApiError {
	constructor() {
		super("Unauthorized", "You must be logged in to access Pots.");
	}
}

export class PotAllocationNotFoundError extends ApiError {
	constructor() {
		super(
			"PotAllocationNotFound",
			"The pot allocation could not be found."
		);
	}
}
