import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ApiContext, AuthContext, authManager, apiClient } from "./context";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<AuthContext.Provider value={authManager}>
			<ApiContext.Provider value={apiClient}>
				<App />
			</ApiContext.Provider>
		</AuthContext.Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
