import { Mutex } from "async-mutex";
import { UnauthorizedError } from "../api/Error";
import AuthConfig from "./config";

const baseURL = "http://localhost:8080";

export default interface IAuthManager {
	login(): void;
	loginCallback(
		accessToken: string,
		refreshToken: string,
		expiresIn: number
	): void;
	logout(): void;
	getToken(): Promise<string>;
	isAuthenticated(): Promise<boolean>;
}

interface PersistedToken {
	accessToken: string;
	refreshToken: string;
	expiry: Date;
}

export class AuthManager {
	private readonly mutex: Mutex;
	constructor(private readonly config: AuthConfig) {
		this.mutex = new Mutex();
	}

	login() {
		if (!this.config.loginUrl) {
			throw new Error("auth loginUrl has not been set");
		}
		window.location.href = this.config.loginUrl;
	}

	loginCallback(
		accessToken: string,
		refreshToken: string,
		expiresIn: number
	) {
		const now = new Date();
		const expiry = now.setSeconds(now.getSeconds() + expiresIn);
		const token = {
			accessToken,
			refreshToken,
			expiry: new Date(expiry),
		};
		this.storeToken(token);
	}

	logout() {
		this.clearToken();
	}

	async getToken(): Promise<string> {
		const token = await this.getTokenFromStorage();
		if (!token) {
			return "";
		}

		const expiry = new Date(token.expiry).getTime();
		const now = new Date(new Date().toUTCString()).getTime();

		if (expiry > now) {
			return token.accessToken;
		}

		await this.mutex.waitForUnlock();
		const release = await this.mutex.acquire();
		await fetch(baseURL + "/oauth/token", {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body:
				`grant_type=refresh_token&refresh_token=` + token.refreshToken,
		})
			.then(res => {
				if (res.status !== 200) {
					throw new Error(
						"Unable to refresh access, server responded with a status code of: " +
							res.status
					);
				}
				return res.json();
			})
			.then(data => {
				this.loginCallback(
					data.accessToken,
					data.refreshToken,
					data.expiresIn
				);
				release();
			})
			.catch(e => {
				console.error(
					"An error occured while trying to refresh access",
					e
				);
				this.clearToken();
				throw new UnauthorizedError();
			});

		return await this.getToken();
	}

	async isAuthenticated(): Promise<boolean> {
		const token = await this.getToken();
		return token.length > 0;
	}

	private storeToken(token: PersistedToken): void {
		if (!this.config.tokenStorageKey) {
			throw new Error("auth token storage key has not been set");
		}
		const data = JSON.stringify(token);
		localStorage.setItem(this.config.tokenStorageKey, btoa(data));
	}

	private async getTokenFromStorage(): Promise<PersistedToken | null> {
		if (!this.config.tokenStorageKey) {
			throw new Error("auth token storage key has not been set");
		}
		const release = await this.mutex.acquire();
		const encodedData = localStorage.getItem(this.config.tokenStorageKey);
		if (!encodedData) {
			release();
			return null;
		}
		try {
			const data = atob(encodedData);
			release();
			return JSON.parse(data) as PersistedToken;
		} catch {
			release();
			return null;
		}
	}

	private clearToken(): void {
		if (!this.config.tokenStorageKey) {
			throw new Error("auth token storage key has not been set");
		}
		localStorage.removeItem(this.config.tokenStorageKey);
	}
}
