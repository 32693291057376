import BaseClient from "./BaseClient";

export interface IWebhooksClient {
	registerHooks(): Promise<void>;
}

export default class WebhooksClient
	extends BaseClient
	implements IWebhooksClient
{
	public async registerHooks(): Promise<void> {
		await this.sendPut("/webhooks/register", null);
	}
}
