import { FunctionComponent } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import IndexPage from "./pages/Index";
import AuthLoginPage from "./pages/auth/Login";
import AuthCallbackPage from "./pages/auth/Callback";
import AuthContinuePage from "./pages/auth/Continue";
import AccountSelectPage from "./pages/account/Select";
import AppPage from "./pages/app/Index";

const App: FunctionComponent = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<IndexPage />} />
				<Route path="/auth/login" element={<AuthLoginPage />} />
				<Route path="/auth/callback" element={<AuthCallbackPage />} />
				<Route path="/auth/continue" element={<AuthContinuePage />} />
				<Route path="/account/select" element={<AccountSelectPage />} />
				<Route path="/app" element={<AppPage />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
