import { FunctionComponent, useCallback, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { useApiCallback } from "../../hooks";
import { PotAllocationLog } from "../../api/models";

export interface LogsProps {
	potAllocationId: string | null;
}

const Logs: FunctionComponent<LogsProps> = ({ potAllocationId }) => {
	const { data: logs } = useApiCallback(
		useCallback(api => api.potAllocations.getLogs(), [])
	);

	const selectedLogs =
		logs?.filter(x => {
			console.log(
				`${potAllocationId} === null || ${x.potAllocationId} === ${potAllocationId}`
			);
			return (
				potAllocationId === null ||
				x.potAllocationId === potAllocationId
			);
		}) ?? [];

	if (selectedLogs.length < 1) {
		return <p>No allocations have been made yet!</p>;
	}

	return (
		<ul className="divide-y divide-slate-100">
			{selectedLogs.map((item, key) => (
				<Log index={key} key={key} item={item} />
			))}
		</ul>
	);
};

export default Logs;

export interface LogProps {
	index: number;
	item: PotAllocationLog;
}

export const Log: FunctionComponent<LogProps> = ({ index, item }) => {
	const [expanded, setExpanded] = useState(false);

	const classes = classNames(
		"flex flex-col items-start space-x-6 p-6 bg-dark-secondary bg-dark-secondary-hover",
		{
			"bg-dark-secondary-lightened": index % 2,
		}
	);
	return (
		<article className={classes} onClick={() => setExpanded(!expanded)}>
			<div className="min-w-0 relative flex-auto">
				<h2 className="font-semibold truncate pr-20">
					{item.merchant}: £{item.amount / 100}
				</h2>
			</div>
			{expanded && (
				<div className="p-2 text-gray-400">
					<p>
						<b>Merchant:</b> {item.merchant}
						<br />
						<b>Amount:</b> £{item.amount / 100}
						<br />
						<b>Time:</b> {moment(item.timestamp).toLocaleString()}
					</p>
				</div>
			)}
		</article>
	);
};
