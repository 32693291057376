import { FunctionComponent, useCallback } from "react";
import { useApiCallback } from "../../hooks";
import { Dropdown } from "../form";

export interface PotDropdownProps {
	potId: string;
	onChange: (potId: string) => void;
	placeholder: string;
}

const PotDropdown: FunctionComponent<PotDropdownProps> = ({
	potId,
	onChange,
	placeholder,
}) => {
	const {
		loading,
		data: pots,
		error,
	} = useApiCallback(useCallback(api => api.pots.get(), []));

	const items =
		pots?.map(p => ({
			label: p.name,
			value: p.id,
		})) ?? [];

	if (error) {
		placeholder = error;
	}
	if (loading) {
		placeholder = "Loading pots...";
	}

	return (
		<Dropdown
			items={items}
			value={potId}
			onChange={onChange}
			placeholder={placeholder}
		/>
	);
};

export default PotDropdown;
