import BaseClient from "./BaseClient";
import { Pot } from "./models";

export interface IPotsClient {
	get(): Promise<Pot[]>;
}

export default class PotsClient extends BaseClient implements IPotsClient {
	public async get(): Promise<Pot[]> {
		return await this.sendGet<Pot[]>("/pots");
	}
}
