import BaseClient from "./BaseClient";
import {
	PotAllocation,
	PotAllocationSummary,
	CreatePotAllocationInput,
	CreatePotAllocationOutput,
	UpdatePotAllocationInput,
	PotAllocationLog,
} from "./models";

export interface IPotAllocationClient {
	get(): Promise<PotAllocationSummary[]>;
	getOne(id: string): Promise<PotAllocation>;
	create(input: CreatePotAllocationInput): Promise<CreatePotAllocationOutput>;
	update(input: UpdatePotAllocationInput): Promise<void>;
	delete(id: string): Promise<void>;
	getLogs(): Promise<PotAllocationLog[]>;
}

export default class PotAllocationClient
	extends BaseClient
	implements IPotAllocationClient
{
	public async get(): Promise<PotAllocationSummary[]> {
		return await this.sendGet<PotAllocationSummary[]>("/pot-allocations");
	}

	public async getOne(id: string): Promise<PotAllocation> {
		return await this.sendGet<PotAllocation>("/pot-allocations/" + id);
	}

	public async create(
		input: CreatePotAllocationInput
	): Promise<CreatePotAllocationOutput> {
		return await this.sendPost<CreatePotAllocationOutput>(
			"/pot-allocations",
			input
		);
	}

	public async update(input: UpdatePotAllocationInput): Promise<void> {
		await this.sendPut("/pot-allocations", input);
	}

	public async delete(id: string): Promise<void> {
		await this.sendDelete("/pot-allocations/" + id);
	}

	public async getLogs(): Promise<PotAllocationLog[]> {
		return await this.sendGet<PotAllocationLog[]>("/pot-allocations/logs");
	}
}
