import { FunctionComponent, PropsWithChildren } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useAuth } from "../hooks";
import { NewButton } from "./PotAllocation";

const Layout: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
	const auth = useAuth();
	const navigate = useNavigate();

	const handleLogout = () => {
		auth.logout();
		navigate("/");
	};

	return (
		<div className="max-w-5xl mx-auto text-white">
			<div className="top-0 z-20 py-2 md:py-6 md:mb-6 px-2 md:px-0">
				<div className="flex mx-auto lg:max-w-5xl items-center justify-between">
					<div className="flex">
						<div className="ml-auto">
							<span className="ml-2">
								<NewButton />
							</span>
						</div>
					</div>
					<div className="flex ">
						<div className="ml-auto">
							<span className="font-bold text-lg align-middle">
								User
							</span>
							<span className="ml-2">
								<button
									className="align-middle items-center p-2 bg-dark-primary rounded-md shadow-sm hover:bg-dark-secondary ease-linear transition-all duration-150"
									onClick={handleLogout}
								>
									<FaSignOutAlt />
								</button>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="p-3 md:px-0">{children}</div>
		</div>
	);
};

export default Layout;
