import { FunctionComponent, useState } from "react";
import NewModal from "./NewModal";

const NewModalButton: FunctionComponent = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<NewModal isOpen={isOpen} onClose={handleToggle} />
			<button
				className="flex align-middle items-center px-4 py-2 bg-dark-primary rounded-md shadow-md hover:bg-dark-secondary ease-linear transition-all duration-150"
				onClick={handleToggle}
			>
				New allocation
			</button>
		</>
	);
};

export default NewModalButton;
