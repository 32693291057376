import { FunctionComponent } from "react";

export interface InputProps {
	value: string;
	placeholder: string;
	onChange: (value: string) => void;
}

const Input: FunctionComponent<InputProps> = ({
	value,
	placeholder,
	onChange,
}) => (
	<input
		className="p-4 border-2 border-dark-secondary focus:border-blue-500 focus:outline-none w-full md:w-96 rounded-md bg-dark-primary"
		placeholder={placeholder}
		value={value}
		onChange={e => onChange(e.target.value)}
	/>
);

export default Input;
