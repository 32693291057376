import { FunctionComponent, useCallback } from "react";
import classNames from "classnames";
import { useApiCallback } from "../../hooks";

export interface ListProps {
	selectedPotAllocationId: string | null;
	onSelect: (potAllocationId: string | null) => void;
}

const List: FunctionComponent<ListProps> = ({
	selectedPotAllocationId,
	onSelect,
}) => {
	const { data: potAllocations } = useApiCallback(
		useCallback(api => api.potAllocations.get(), [])
	);

	const handleClick = (id: string) => (): void => {
		if (selectedPotAllocationId === id) {
			onSelect(null);
		} else {
			onSelect(id);
		}
	};

	return (
		<ul className="divide-y divide-slate-100">
			{potAllocations?.map((item, key) => {
				const classes = classNames(
					"flex items-start space-x-6 p-6 bg-dark-secondary bg-dark-secondary-hover",
					{
						"bg-dark-secondary-lightened":
							key % 2 || item.id === selectedPotAllocationId,
					}
				);

				return (
					<article
						className={classes}
						key={key}
						onClick={handleClick(item.id)}
					>
						<div className="min-w-0 relative flex-auto">
							<h2 className="font-semibold truncate pr-20">
								{item.name}
							</h2>
						</div>
					</article>
				);
			})}
		</ul>
	);
};

export default List;
