import { FunctionComponent } from "react";
import Input from "./Input";

export interface NumberInputProps {
	value?: number;
	placeholder: string;
	onChange: (value: number) => void;
}

const NumberInput: FunctionComponent<NumberInputProps> = ({
	value,
	placeholder,
	onChange,
}) => {
	const handleChange = (value: string): void => {
		const numValue = parseInt(value);
		if (!isNaN(numValue)) {
			onChange(numValue);
		}
	};

	return (
		<Input
			value={value?.toString() ?? ""}
			onChange={handleChange}
			placeholder={placeholder}
		/>
	);
};

export default NumberInput;
