import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../hooks";

const Login: FunctionComponent = () => {
	const auth = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		auth.isAuthenticated().then(ok => {
			if (ok) {
				navigate("/app");
			} else {
				auth.login();
			}
		});
	}, [auth, navigate]);

	return <p>LOGGING IN...</p>;
};

export default Login;
