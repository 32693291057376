import { IAuthManager } from "../auth";
import ApiError, { UnauthorizedError } from "./Error";
import { ErrorModel } from "./models";

export default class BaseClient {
	public readonly defaultError: ApiError = new ApiError(
		"ApiError",
		"An error occured while comminucating with the server."
	);

	constructor(
		protected readonly auth: IAuthManager,
		protected readonly baseUrl: string
	) {}

	protected async sendGet<T>(url: string): Promise<T> {
		const data = await this.fetch<T>(url, "GET");
		return data as T;
	}

	protected async sendPost<T>(url: string, body: any): Promise<T> {
		const data = await this.fetch<T>(url, "POST", body);
		return data as T;
	}

	protected async sendPut(url: string, body: any): Promise<void> {
		await this.fetch<null>(url, "PUT", body);
	}

	protected async sendPatch(url: string, body: any): Promise<void> {
		await this.fetch<null>(url, "PATCH", body);
	}

	protected async sendDelete(url: string): Promise<void> {
		await this.fetch<null>(url, "DELETE");
	}

	protected async fetch<T>(
		url: string,
		method: string,
		body?: any
	): Promise<T | null> {
		const request: globalThis.RequestInit = {
			method: method,
		};
		const headers = new Headers();
		if (method !== "GET" && body) {
			request.body = JSON.stringify(body);
			headers.set("Content-Type", "application/json");
		}
		request.headers = headers;
		await this.attachAcessToken(request);

		try {
			const response = await fetch(this.buildTargetUrl(url), request);
			if (response.status === 204) {
				return null;
			}
			const contentType = response.headers.get("Content-Type");
			if (!contentType?.includes("application/json")) {
				console.error(
					"The API returned an unexpected, non-JSON response."
				);
				throw this.defaultError;
			}
			await this.throwIfError(response);
			const data = await response.json();
			return data as T;
		} catch (e) {
			console.error("An error occured when requesting the API", e);
			throw this.defaultError;
		}
	}

	protected async attachAcessToken(
		request: globalThis.RequestInit
	): Promise<void> {
		const accessToken = await this.auth.getToken();
		if (!accessToken) {
			throw new UnauthorizedError();
		}
		request.headers = {
			...request.headers,
			Authorization: "Bearer " + accessToken,
		};
	}

	protected async throwIfError(response: globalThis.Response): Promise<void> {
		if (response.status === 200) {
			return;
		}
		const data = await response.json();
		const error = data as ErrorModel;
		throw new ApiError(error.error, error.message);
	}

	protected buildTargetUrl(url: string): string {
		let baseUrl = this.baseUrl;
		if (baseUrl && baseUrl.substring(baseUrl.length - 1) === "/") {
			baseUrl = baseUrl.substring(0, baseUrl.length - 2);
		}
		if (url && url.substring(0, 1) === "/") {
			url = url.substring(1);
		}
		let targetUrl = baseUrl;
		if (!url) {
			return targetUrl;
		}
		if (!targetUrl) {
			return url;
		}
		return targetUrl + "/" + url;
	}
}
