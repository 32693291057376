import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../hooks";

const CallbackPage: FunctionComponent = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	// Read the query params and save token.
	useEffect(() => {
		if (isLoggedIn) {
			navigate("/app");
			return;
		}

		const params = new URLSearchParams(window.location.search);
		const accessToken = params.get("access_token");
		const refreshToken = params.get("refresh_token");
		const expiresIn = params.get("expires_in");

		params.forEach((v, k) => {
			console.log("Query param: " + k + "=" + v);
		});

		if (accessToken && refreshToken && expiresIn && !isLoggedIn) {
			auth.loginCallback(accessToken, refreshToken, parseInt(expiresIn));
			setIsLoggedIn(true);
			navigate("/auth/continue");
		}
	}, [auth, isLoggedIn, navigate]);

	return <p>LOGGING IN...</p>;
};

export default CallbackPage;
