import { ChangeEventHandler, FunctionComponent } from "react";

export interface DropdownItemProps {
	value: string;
	label: string;
}

export interface DropdownProps {
	items: DropdownItemProps[];
	value: string;
	onChange: DropdownChangeHandler;
	placeholder?: string;
}

export interface DropdownChangeHandler {
	(value: string): void;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
	items,
	value,
	onChange,
	placeholder,
}) => {
	const handleChange: ChangeEventHandler<HTMLSelectElement> = e => {
		const value = e.target.value;
		onChange(value);
	};

	return (
		<select
			value={value}
			onChange={handleChange}
			className="p-4 border-2 border-dark-secondary focus:border-blue-500 focus:outline-none w-full md:w-96 rounded-md bg-dark-primary"
		>
			{placeholder && <option value="">{placeholder}</option>}
			{items.map((item, key) => (
				<option value={item.value} key={key}>
					{item.label}
				</option>
			))}
		</select>
	);
};

export default Dropdown;
