import { useContext } from "react";
import { IApiClient } from "../api/ApiClient";
import { ApiContext } from "../context";

const useApi = (): IApiClient => {
	const api = useContext(ApiContext);

	return api;
};

export default useApi;
