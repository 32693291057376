import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, NumberInput, TextInput } from "../form";
import PotDropdown from "./PotDropdown";
import { useApi } from "../../hooks";

export interface NewModalProps {
	isOpen: boolean;
	onClose: () => void;
}

const NewModal: FunctionComponent<NewModalProps> = ({ isOpen, onClose }) => {
	const [pattern, setPattern] = useState<string>("");
	const [potId, setPotId] = useState<string>("");
	const [amount, setAmount] = useState<number | undefined>();
	const [notify, setNotify] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const api = useApi();

	const handleNotifyChange = (value: string) => {
		setNotify(value === "1");
	};

	const handleCreate = async () => {
		if (loading) {
			return;
		}

		setLoading(true);

		const userInfo = await api.whoAmI();
		if (userInfo.accountId) {
			await api.potAllocations.create({
				accountId: userInfo.accountId,
				potId: potId,
				pattern: pattern,
				amount: amount ?? 0,
				matchAmount: !amount || amount === 0,
				notify: notify,
			});
		}

		setLoading(false);
		onClose();
		setPattern("");
		setPotId("");
		setNotify(false);
	};

	if (!isOpen) {
		return null;
	}

	return (
		<>
			<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
				<div className="relative w-auto my-6 mx-auto max-w-3xl">
					<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-dark-secondary outline-none focus:outline-none">
						<div className="flex items-start justify-between p-5 border-b border-solid border-dark-secondary rounded-t">
							<h3 className="text-3xl font-semibold">
								New pot allocation
							</h3>
							<button
								className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
								onClick={() => navigate("/")}
							>
								<span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
									X
								</span>
							</button>
						</div>
						<div className="relative px-6 py-4 flex-auto">
							<TextInput
								placeholder="Who should we be looking for?*"
								value={pattern}
								onChange={value => setPattern(value)}
							/>
						</div>
						<div className="relative px-6 py-4 flex-auto">
							<PotDropdown
								potId={potId}
								onChange={id => {
									setPotId(id);
								}}
								placeholder="Which pot should be allocated?*"
							/>
						</div>
						<div className="relative px-6 py-4 flex-auto">
							<NumberInput
								placeholder="How much should we allocate?"
								value={amount}
								onChange={value => setAmount(value)}
							/>
						</div>
						<div className="relative px-6 py-4 flex-auto">
							<Dropdown
								items={[
									{ label: "Yes", value: "1" },
									{ label: "No", value: "0" },
								]}
								placeholder="Should we notify you on allocation?"
								onChange={handleNotifyChange}
								value={notify ? "1" : "0"}
							/>
						</div>
						<div className="flex items-center justify-end p-6 border-t border-solid border-dark-primary rounded-b">
							<button
								className="bg-blue-500 text-white active:bg-blue-600 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:opacity-25"
								type="button"
								onClick={handleCreate}
							>
								{loading ? "Creating" : "Create"}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</>
	);
};

export default NewModal;
