import BaseClient from "./BaseClient";
import { Account } from "./models";

export interface IAccountsClient {
	get(): Promise<Account[]>;
}

export default class AccountsClient
	extends BaseClient
	implements IAccountsClient
{
	public async get(): Promise<Account[]> {
		return await this.sendGet<Account[]>("/accounts");
	}
}
