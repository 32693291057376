import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Layout } from "../../components";
import { List, Logs } from "../../components/PotAllocation";
import { useApiCallback } from "../../hooks";

const App: FunctionComponent = () => {
	const { data: userInfo } = useApiCallback(
		useCallback(api => api.whoAmI(), [])
	);
	const navigate = useNavigate();
	const [selectedPotAllocationId, setSelectedPotAllocationId] = useState<
		string | null
	>(null);

	useEffect(() => {
		if (!userInfo) {
			return;
		}
		if (!userInfo.accountId) {
			navigate("/account/select");
		}
	}, [userInfo, navigate]);

	return (
		<Layout>
			<div className="grid grid-flow-row auto-rows-max">
				<div className="grid grid-cols-3 gap-4">
					<div>
						<List
							selectedPotAllocationId={selectedPotAllocationId}
							onSelect={setSelectedPotAllocationId}
						/>
					</div>
					<div className="col-span-2">
						<Logs potAllocationId={selectedPotAllocationId} />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default App;
