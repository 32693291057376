import { FunctionComponent, useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { Dropdown } from "../../components/form";
import { useApi, useApiCallback } from "../../hooks";

const Select: FunctionComponent = () => {
	const {
		data: accounts,
		error,
		loading,
		setLoading,
		setError,
	} = useApiCallback(useCallback(api => api.accounts.get(), []));
	const [selectedAccount, setSelectedAccount] = useState("");
	const navigate = useNavigate();
	const api = useApi();

	const handleSelect = async () => {
		if (loading) {
			return;
		}

		setLoading(true);
		try {
			await api.preferences.setPreferredAccount({
				accountId: selectedAccount,
			});
			navigate("/app");
		} catch (err: any) {
			setError(err.message);
		}

		setLoading(false);
	};

	return (
		<div className="max-w-md mx-auto text-white">
			<div className="top-0 pt-14 py-6 md:mx-6 px-2">
				<div className="text-2xl mb-6">
					Select a <span className="text-blue-500">Monzo</span>{" "}
					account.
				</div>
				{error && <p className="text-red-600 mb-2">{error}</p>}
				<div className="w-100">
					<Dropdown
						items={
							accounts?.map(a => ({
								label: a.name,
								value: a.id,
							})) ?? []
						}
						value={selectedAccount}
						onChange={setSelectedAccount}
						placeholder="Select an account..."
					/>
				</div>
			</div>
			<div className="py-6 md:mx-6 px-2">
				<div className="flex mx-auto lg:max-w-5xl items-center justify-end">
					<div className="flex">
						<div className="ml-auto">
							<span className="ml-2">
								<button
									className="align-middle items-center p-2 bg-dark-primary rounded-md shadow-sm hover:bg-dark-secondary ease-linear transition-all duration-150"
									onClick={handleSelect}
									disabled={selectedAccount === ""}
								>
									Select
								</button>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Select;
