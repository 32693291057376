export default interface AuthConfig {
    loginUrl?: string
    tokenStorageKey?: string
}

export class AuthConfigBuilder {
    constructor(private config: AuthConfig) {
        config.tokenStorageKey = config.tokenStorageKey ?? "access_token"
    }

    setLoginUrl(loginUrl: string): AuthConfigBuilder {
        this.config.loginUrl = loginUrl;
        return this;
    }

    build(): AuthConfig {
        return this.config
    }
}