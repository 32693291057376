import { useContext } from "react";
import { IAuthManager } from "../auth";
import { AuthContext } from "../context";

const useAuth = (): IAuthManager => {
	const authManager = useContext(AuthContext);
	return authManager;
};

export default useAuth;
